import React from "react";

function Footer(){

    const year= new Date().getFullYear();

    return (
    
    <footer> CopyRight © {year} Damjan Tepic</footer>
    
    )
};

export default Footer;