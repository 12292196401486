import React from "react";
import Footer from "./Footer";

function App(){

    return (
        <section id="hero">
        <div className="container">
		
		<div class="item">
			<i class="loader --3"></i>
		</div>
        
        <Footer/>

        </div>
        </section>
        
        
    )
};

export default App;
